@font-face {
  font-family: "Frag";
  src: url("/public/assets/font/Fragmentcore.otf");
  font-weight: 100;
  font-style: normal;

}

@font-face {
  font-family: "Salad";
  src: url("/public/assets/font/saladfingers.ttf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Cutive";
  src: url("/public/assets/font/Cutive/cutive/Cutive-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

.home {
  width: 100%;
  /* height: 77vh; */
  /* background-image: url("/public/assets/audio_bg.mp3");  */
}

.carousel-main {
  height: 75vh;
  width: 100vw;
}

.slide-image {
  height: 100%;
}

.carousel-item {
  height: 75vh;
}

/* .carousel-item {
     display: flex !important; 
    justify-content: center !important;
    align-items: center !important;
} */
.slider-image-sec {
  width: 35%;
}

.slider-content-sec p {
  font-family: "Frag";
  width: 68%;
  font-weight: bold;
  letter-spacing: 2px;
}

/* .slider-main-sec {
 width: 70vw;
    display: flex !important;
   justify-content: space-evenly !important;
    align-items: center !important;
    margin: 0 auto;
    padding-top: 5%;
} */

.carousel-item {
  position: relative;
}

.slider-main-sec {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  width: 70vw;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 10%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.slider-image-sec2 {
  width: 30%;
}

.slider-image-sec {
  max-width: 30%;
  width: 100%;
}

.slider-content-sec.text-light {
  max-width: 50%;
  width: 100%;
}



/* .slider-image-sec22 {
    width: 28vw;
    margin-left: 11%;
} */
.slider-content-main-title {
  font-family: "Salad";
  width: 100%;
  line-height: 67px;
  letter-spacing: 3px;
  font-weight: 600;
  font-size: 50px;
  text-transform: uppercase;
}

button.btn.btn-danger {
  font-family: "Cutive";
  background-color: #901717;
  border-radius: 50px;
  border-color: #901717;
  padding: 14px 22px;
  font-size: 13px;
}

/* animation text css */
.carousel-caption {
  position: absolute;
  right: 11%;
  bottom: 13.25rem;
  left: 10%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
  width: 32%;
}

.slider-video {
  width: 100vw;
  height: 75vh;
}

video:-webkit-full-page-media {
  margin: 0 !important;
  width: 100vw;
  object-fit: cover;
  /* max-width: 100vw; */
  /* height: 100vh; */
}

.responsive-iframe {
  position: fixed;
  /* position: relative; */
  left: 0px;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 87%;
}

.slider-content-sec.text-light.ml-8 {
  padding-left: 42px;
}

/*If screen is 2000px or less then these media query works*/
@media screen and (max-width: 25600px) {
  .slider-content-sec p {

    width: 100%;
  }

  .slider-main-sec {
    left: 12%;
  }
}

/*If screen is 1920px or less then these media query works*/
@media screen and (max-width: 1920px) {
  .slider-main-sec {
    width: 90vw;
  }

  .slider-image-sec {
    /* max-width: 26%; */
    width: 100%;
  }

  .slider-content-sec p {
    width: 80%;
  }


}

/*If screen is 1600px or less then these media query works*/
@media screen and (max-width: 1700px) {
  .slider-main-sec {
    width: 90vw;
    padding-top: 1%;
  }

  .slider-content-sec p {
    width: 82%;
  }

  .slider-image-sec {
    max-width: 33%;
  }
}

/*If screen is 1600px or less then these media query works*/
@media screen and (max-width: 1440px) {
  .slider-main-sec {
    width: 90vw;
    left: 5%;

  }
}

/*If screen is 1390px or less then these media query works*/
@media screen and (max-width: 1390px) {
  .slider-main-sec {
    width: 90vw;

  }

  .slider-content-sec.text-light {
    max-width: 56%;
  }

  .slider-content-sec p {
    width: 90%;
  }
}

/*If screen is 1024px or less then these media query works*/
@media screen and (max-width: 1024px) {
  .slider-main-sec {
    width: 90vw;
  }

  .slider-content-sec p {
    width: 90%;
  }

  img.w-100.slide-image-sec2 {
    padding-left: 65px;
  }

  .slider-content-main-title {
    line-height: 44px;
    font-size: 35px;
  }
}

/*If screen is 880px or less then these media query works*/
@media screen and (max-width: 880px) {
  .home {
    width: 100%;
    height: 59vh;
    /* padding: 60PX 0PX; */
    /* background-image: url(/public/assets/audio_bg.mp3); */
  }

  .slider-image-sec2 {
    width: 32vw;
  }

  /* .carousel-item {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
} */
  #videobg {
    height: 100vh;
  }

  .slider-content-sec.text-light {
    padding-left: 20px;
    /* width: 60vw; */
  }

  button.btn.btn-danger {
    padding: 8px 18px;
  }

  .slider-content-main-title {
    font-family: "Salad";
    font-size: 42px;
    width: 92%;
  }
}

/*If screen is 820px or less then these media query works*/
@media screen and (max-width: 820px) {
  img.header-logo1 {
    width: 25%;
  }

  img.header-logo2 {
    width: 25%;
  }

}

/*If screen is 768px or less then these media query works*/
@media screen and (max-width: 768px) {
  .slider-image-sec22 {
    padding-left: 24px;
  }

  .slider-content-sec.text-light {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .slider-image-sec2 {
    width: 50vw;
  }

  .slider-content-main-title {
    line-height: 51px;
  }

  .slider-main-sec {
    width: 84vw;
    left: 10%;
  }

  .slider-image-sec {
    margin-bottom: 14px;
    max-width: 34%;
  }

  .carousel-caption {
    position: absolute;
    right: 2%;
    bottom: 18.25rem;
    left: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .home {
    height: auto;
  }
}

/*If screen is 550px or less then these media query works*/
@media screen and (max-width: 550px) {
  .slider-main-sec {
    width: 84vw;
    flex-direction: column;
  }

  .slider-content-main-title {
    line-height: 46px;
    font-size: 38px;
  }

  .slider-content-sec.text-light {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 425px) {
  .slider-image-sec22 {
    padding-left: 0px;
  }

  .slider-main-sec {
    width: 100vw;
    flex-direction: column;
    display: flex;
    padding: 19px 18px;
    left: 0%;
  }

  .slider-image-sec {
    max-width: 50vw;
  }

  .slider-content-sec.text-light {
    max-width: 100%;
    padding-left: 0px;
  }

  .slider-content-main-title {
    font-size: 25px;
    line-height: 37px;
    text-align: center;
    width: 100%;
  }

  .slider-content-sec.text-light.ml-8 {
    padding-left: 0;
  }

  .d-flex.carousel-item {
    display: block !important;
  }

  button.btn.btn-danger {
    padding: 9px 22px;
  }

  .slider-content-sec p {
    padding: 18px;
    width: 100%;
    font-size: 14px;
    text-align: center;
  }

  img.header-logo1 {
    width: 45%;
  }

  img.header-logo2 {
    width: 45%;
  }

  .carousel-caption {
    width: 92%;
  }
}