@font-face {
  font-family: "Frag";
  src: url("/public/assets/font/Fragmentcore.otf");
  font-weight: 100;
  font-style: normal;
}
.page-footer{
  --bs-bg-opacity: 0.5;
    background-color: #00000054;  
   bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    
}
.page-footer-faq{
   background-color: #1A1A1A;  
    position: relative;
    z-index: 1;
    margin-top: 22px
}
.footer {
    padding: 16px 0px;
     /* --bs-bg-opacity:0.5;
     background-color: #00000054; */
     margin: 0 auto;
     max-width: 1560px
}
.social-link img {
    width: 35px;
    margin-right: 16px;
}    
.footer-text-first{
 font-family: "Frag";
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    /* padding: 0px 42px 0px 0px; */
    text-transform: uppercase;   
}
.footer-text{
    font-family: "Frag";
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    /* padding: 0px 42px 0px 0px; */
    text-transform: uppercase;   
} 
.footer-main{
  position: relative;
    z-index: 1;
}  
/*If screen is 1600 or less then these media query works*/
@media screen and (max-width: 1600px) { 
.footer {
    padding: 16px 20px;
}
}
/*If screen is 1460 or less then these media query works*/
@media screen and (max-width: 1460px) { 
.footer {
    padding: 16px 38px;
}
}
/*If screen is 1390 or less then these media query works*/
@media screen and (max-width: 1390px) { 
.footer {
    padding: 16px 50px;
}
}
/*If screen is 1024px or less then these media query works*/
@media screen and (max-width: 1024px) {   
.page-footer {
    padding: 0 32px;
    position: relative;
}
.footer img {
    width: 35px;
    padding-top: 6px;
}
.footer-text {
    font-size: 16px;
}
}
/*If screen is 768px or less then these media query works*/
@media screen and (max-width: 768px) {   
  .footer {
    padding: 16px 21px;
  }         
.social-link img {
    width: 34px;
}
.footer-text {
    font-size: 16px;
}
}
/*If screen is 550px or less then these media query works*/
@media screen and (max-width: 550px) {
    .page-footer {
    position: relative;
}
.social-link img {
    width: 30px;
}
.footer-text-first {
    font-size: 12px;
}
.footer-text {
    font-size: 13px;
}
}
/*If screen is 420px or less then these media query works*/
@media screen and (max-width: 425px) {
.social-link img {
    width: 32px;
}
.page-footer{
   position: relative;
}
.footer {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
    padding: 18px 0px;
   
}
.footer img {
    margin: 5px;
}
.footer-text{
  font-size: 14px;
  padding: 12px 0px;
}
.footer-text-first {
    padding-bottom: 7px;
    font-size: 12px;
}

}