@font-face {
  font-family: "Frag";
  src: url("/public/assets/font/Fragmentcore.otf");
  font-weight: 100;
  font-style: normal;
}

body {
  background-color: black;
}
.faq-main {
  background-color: #000000;
}
.funding-sec {
  background-color: #1a1a1a;
  padding: 20px 36px;
  font-family: "Frag";
  margin-bottom: 38px;
  position: relative;
  top: 23px;
  font-size: 20px;

  /* margin-top: 20px; */
}
.clickhere-text {
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: bold;
}
span.click-text:hover {
    color: #8E8E8E;
}
.next-icon{
  filter: grayscale(1);
}
span.click-text:hover .next-icon{
/* filter: hue-rotate(100deg); */
filter: invert(50%)
}
button.accordion-button {
  background: #901717 !important;
  color: #fff !important;
  border-radius: 5px;
}
.funding-sec a {
  text-decoration: none;
  color: #fff;
}
h1.faq-tilte-main.text-light {
  font-family: "Frag";
  padding: 24px 0px;
}
.list-text {
  font-size: 14px;
}
h2.accordion-header {
  font-family: "Frag";
}
.accordion-item {
  border-color: #901a16;
  margin: 12px 0px;
  border-radius: 5px;
  font-family: "Frag";
}
.accordion-button:not(.collapsed)::after {
  background-image: url("/public/assets/down_arrow.png");
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  background-image: url("/public/assets/down_arrow.png");
}
button.accordion-button:hover {
  background: #850804 !important;
  color: #fff !important;
}

.faq-main h1,
.faq-main h2,
.faq-main h3,
.faq-main h4,
.faq-main h5,
.faq-main h6,
.faq-main p {
  font-family: Frag !important;
  font-weight: 800 !important;
  letter-spacing: 1px !important;
}
.accordion-button {
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 1px;
}
.accordion-body {
  /* font-size: 19px; */
  font-weight: 800;
  letter-spacing: 1px!important;
    font-size: 18px;
    line-height: 28px;
    color: #676767;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.accordion-item .accordion-button {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}
.accordion-button:not(.collapsed) {
  /* box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #901717; */
  box-shadow: none;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.accordion-button:focus {
  border-color: #901717;
  box-shadow: none;
}
/*If screen is 550px or less then these media query works*/
@media screen and (max-width: 750px) {
  .funding-sec {
    flex-direction: column;
  }
}
@media screen and (max-width: 400px) {
  .funding-sec.d-flex.justify-content-between.text-light {
    flex-direction: column;
  }
  /* .faq-main {
    padding: 10px;
  } */

  .funding-sec {
    font-size: 16px;
  }
  h1{
    font-size: 16px;
  }
  .accordion-button {
    font-size: 16px;
  }

  .accordion-body {
      font-size: 14px;
  }

  .funding-sec.d-flex.justify-content-between.text-light {
    flex-direction: column !important;
  }
}
@media screen and (max-width: 470px) {
  .funding-sec.d-flex.justify-content-between.text-light {
    flex-direction: column !important;
  }
}
