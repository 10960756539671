#videobg {
  position: fixed;
  z-index: -1;
  width: 100%;
  /* height: 100vh; */
  opacity: 0.7;
}
.defualt-layout {
    overflow-x: hidden;
}
.defualt-layout-faq {
  /* background-color: #000; */
  overflow-x: hidden;
  /* background-image: url("/public/assets/95267e09-3239-42f3-acb1-52fb0cf9fb6b.mp3"); */
}
@media screen and (max-width: 2800px) {
#videobg {
    position: fixed;
    z-index: -1;
    width: 100%;
    height: 100vh;
    opacity: 0.7;
    object-fit: cover;
}
}
@media screen and (max-width: 820px) {
  .defualt-layout {
    /* background-color: #000; */
    /* background-image: url("/public/assets/95267e09-3239-42f3-acb1-52fb0cf9fb6b.mp3"); */
  }
  
}

@media screen and (max-width: 470px) {
#videobg {
    position: fixed;
    /* z-index: -1; */
    width: auto;
    height: auto;
}
}

.playAndPauseButtonDivStyle {
  position: relative;
  z-index: 9999;
  text-align: right;
  padding: 0 8px 30px 8px;
}