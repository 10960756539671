@font-face {
  font-family: "Frag";
  src: url("/public/assets/font/Fragmentcore.otf");
  font-weight: 100;
  font-style: normal;
}
span.navbar-toggler-icon {
  filter: brightness(0) invert(1);
}
.navbar-toggle-icon {
  filter: brightness(0) invert(1);
}
.basic-navbar-nav {
  border: none;
}
.navbar-toggler {
  border: none;
}
.navbar-toggler:focus {
  box-shadow: none;
}
span.navbar-brand {
  width: 50vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.header {
  background-color: rgb(0, 0, 0);
  width: 100%;
  font-family: Frag;
}
img.header-logo1 {
  width: 60%;
  margin-right: 36px;
  cursor: pointer;
}
img.header-logo2 {
  width: 46%;
  cursor: pointer;
}
img.header-logo3 {
  width: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}
img.header-logo-last {
  width: 40%;
}
a.nav-link {
  color: #fff;
  font-family: Frag;
  margin: 0px 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  line-height: 29px;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 18px;
}
div#basic-navbar-nav {
  width: 100%;
  max-width: 56vw;
}
.nav-link:hover {
  color: rgba(255, 255, 255, 0.776);
}
.nav-link-other:hover {
  color: rgba(255, 255, 255, 0.776) !important;
}
.header-last-logo {
  max-width: 6.6vw;
}
.nav-link-other {
  text-decoration: none;
  font-family: Frag;
  margin: 0px 15px;
  justify-content: center;
  align-items: center;
  display: flex;
  line-height: 29px;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 18px;
}
.main-header {
  padding: 11px 0px;
  --bs-bg-opacity: 0.5;
  background-color: #00000054;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}
.nav-main {
  max-width: 1560px;
  margin: 0 auto;
}
.d-flex.justify-content-end.navbar-nav {
  width: 100%;
}
.main-header-faq {
 background-color: #1a1a1a;
    padding: 11px 0px;
    --bs-bg-opacity: 0.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
}
.hero {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.75);
}
.nav-link:focus,
.nav-link:hover {
  color: rgba(255, 255, 255, 0.776);
}

/* media query */

/*If screen is 2000px or less then these media query works*/
/* @media screen and (max-width: 2000px) {
  .nav-main {
    max-width: 1800px;
  }
} */
/*If screen is 16000px or less then these media query works*/
@media screen and (max-width: 1600px) {
  .nav-main { 
    padding: 8px 0;
    width: 100%;
    margin: 0;
    justify-content: space-between;
}
}
/*If screen is 1460px or less then these media query works*/
@media screen and (max-width: 1460px) {
  .main-header {
    padding: 11px 30px;
}
a.nav-link {
    font-size: 16px;
}
.nav-link-other{
  font-size: 16px;
}
}
/*If screen is 1390px or less then these media query works*/
@media screen and (max-width: 1390px) {
  span.navbar-brand {
    width: 38vw;
  }
  .header-last-logo {
    max-width: 7vw;
    margin: 0 14px;
}
  a.text-white.nav-link-other {
    font-size: 12px;
    margin: 0px 14px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 14px;
    margin: 0px 14px;
  }
}
/*If screen is 1280px or less then these media query works*/
@media screen and (max-width: 1280px) {
  a.nav-link {
    font-size: 13px;
  }
  .nav-link-other {
    font-size: 13px;
  }
}
/*If screen is 1256px or less then these media query works*/
@media screen and (max-width: 1256px) {
span.navbar-brand {
    width: 30vw;
}
img.header-logo1 {
    width: 85%;
}
img.header-logo2 {
    width: 64%;
}
.header-last-logo {
    max-width: 8vw;
}
.navbar-expand-lg .navbar-nav .nav-link {
    font-size: 11px
}
a.text-white.nav-link-other {
    font-size: 11px;
}
}
/*If screen is 1179x or less then these media query works*/
@media screen and (max-width: 1179px) {
span.navbar-brand {
    width: 25vw;
}
img.header-logo1 {
    width: 85%;
}
img.header-logo2 {
    width: 64%;
}
.header-last-logo {
    max-width: 8vw;
}
.navbar-expand-lg .navbar-nav .nav-link {
    font-size: 12px;
    margin: 0 10px;
}
a.text-white.nav-link-other {
    font-size: 12px;
    margin: 0 10px;
}
}
/*If screen is 1148px or less then these media query works*/
@media screen and (max-width: 1148px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 11px;
    margin: 0 10px;
}
a.text-white.nav-link-other {
    font-size: 11px;
    margin: 0 10px;
}
span.navbar-brand {
    width: 20vw;
}
img.header-logo1 {
    width: 100%;
}
}
/*If screen is 1000px or less then these media query works*/
@media screen and (max-width: 1024px) {
  button.navbar-toggler.collapsed{
    padding: 0px;
  }
  div#basic-navbar-nav {
    width: 100%;
    max-width: 100%;
}
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 11px;
   margin: 0px 10px
}
a.text-white.nav-link-other {
    font-size: 11px;
    margin: 6px 10px
}
  .navbar-toggle-icon {
    filter: brightness(0) invert(1);
  }
  .basic-navbar-nav {
    border: none;
  }
  span.navbar-brand {
    width: 40vw;
    margin: 0 auto;
  }
  img.header-logo1 {
    width: 90%;
  }
  img.header-logo2 {
    width: 75%;
  }
  img.header-logo3 {
    width: 90px;
  }
  .d-flex.justify-content-end.navbar-nav {
    padding: 10px
  }
  a.nav-link {
    padding: 6px;
  }
  
  .header-last-logo {
    max-width: 12vw;
    margin: 6px;
  }
}


/*If screen is 991px or less then these media query works*/
@media screen and (max-width: 991px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 14px;
}
a.text-white.nav-link-other{
    font-size: 14px;
  
}
  span.navbar-brand {
   width: 70vw;
}
img.header-logo1 {
    width: 50%;
}
img.header-logo2 {
    width: 45%;
}
.d-flex.justify-content-end.navbar-nav {
    padding: 10px;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}
.navbar-toggler{
  padding: 0px;
    /* position: relative;
    right: 85px; */
}
button.navbar-toggler.collapsed{
  /* right: 0; */
}
span.navbar-toggler-icon{
  padding: 0;
}
button.navbar-toggler.collapsed{
 padding: 0;
}

}
/*If screen is 800px or less then these media query works*/
@media screen and (max-width: 820px) {
  .nav-sec {
    display: flex;
    padding: 16px 30px;
  }
}
/*If screen is 768px or less then these media query works*/
@media screen and (max-width: 768px) {
  span.navbar-brand {
    width: 75vw;
  }
  .d-flex.justify-content-end.navbar-nav {
    justify-content: flex-start !important;
    align-items: flex-start;
    padding: 10px 0;
  }
  a.nav-link {
    padding: 6px;
  }
  a.text-white.nav-link-other {
    padding: 6px;
  }
  .header-last-logo {
    max-width: 12vw;
  }
  button.navbar-toggler.collapsed {
    background: transparent;
    border: none;
  }
  span.navbar-toggler-icon {
    filter: brightness(0) invert(1);
  }
  .navbar-toggler {
    background: transparent;
    border: none;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
}
/*If screen is 768px or less then these media query works*/
@media screen and (max-width: 768px) {
  span.navbar-brand {
    width: 75vw;
  }
}
/*If screen is 675px or less then these media query works*/
@media screen and (max-width: 675px) {
  span.navbar-brand {
    width: 70vw;
  }
}
/*If screen is 615px or less then these media query works*/
@media screen and (max-width: 615px) {
  span.navbar-brand {
    width: 65vw;
}
img.header-logo1 {
    width: 60%;
}
}

/*If screen is 470px or less then these media query works*/
@media screen and (max-width: 470px) {
  div#basic-navbar-nav {
    width: 100%;
    max-width: 100%;
}
  .main-header.navbar.navbar-expand-lg.navbar-light {
    padding: 24px;
  }
  span.navbar-brand {
    width: 100%;
}
  img.header-logo1 {
    width: 80%;
  }
  img.header-logo2 {
    width: 80%;
  }
  .footer-text {
    bottom: -9px !important;
    text-align: center !important;
  }
}

/*If screen is 420px or less then these media query works*/
@media screen and (max-width: 425px) {
  .navbar-toggle-icon {
    filter: brightness(0) invert(1);
  }
  .basic-navbar-nav {
    border: none;
  }
  .main-header-faq {
    padding: 20px 12px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 14px;
    margin: 0px;
  }
  .d-flex.justify-content-end.navbar-nav {
    justify-content: flex-start !important;
    align-items: start;
  }
  a.text-white.nav-link-other {
    font-size: 14px;
    margin: 0px;
  }
  .header-last-logo {
    max-width: 50%;
  }
  img.header-logo3 {
    margin: 3px;
  }
  /* span.navbar-brand {
    width: 50vw;
  } */
  img.header-logo1 {
    width: 100%;
    margin: 0;
  }
  img.header-logo2 {
    /* width: 100%; */
  }
  .main-header {
    padding: 11px 11px;
  }
}
/*If screen is 320px or less then these media query works*/
